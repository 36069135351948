::-webkit-scrollbar {
  display:none
}
#intro{
  position:absolute;
  color:black;
  text-align: center;
  border-width:1px;
  top:55%;
  left:50%;
  transform: translate(-50%, -50%)  
}
#start{
  color:grey;
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
#contact,#illustration{ 
  position:absolute;
  top:50%;
  left:50%;
  width: 50px;
  transform: translate(-50%, -50%);
  z-index:1
}

/* Gallery */
#menu1{
  position: absolute;
  width:50px;  
  top:15%;
  left:15%;
  transform: translate(-50%, -50%);
  z-index:1
}
.content{   
  position: absolute;
  top:14.98%;
  left:14.98%;
  right: 15%;
  bottom: 15%;
  overflow: auto;
}
.gallery{
  max-width: 100%;
  max-height: 100%;
  background-color:white;
  z-index:-1
}

#menu2,#clo{
  position: absolute;
  width:50px;
  top:15%;
  left:85%;
  transform: translate(-50%, -50%);
  z-index:1
}

.Box{
  float:right;
  width: fit-content;   
}
.ac{ 
  padding-left:2.3px;
  padding-right:2.3px;
  border-bottom: none;
}
.a{ 
  padding:2.3px;
  border-width:1px;
}
.link{
  text-decoration: none;
  color:black
}
.frame{
  width:90%;
  max-width:900px;
  max-height:900px;
  min-width:300px;
  min-height:150px;
  resize: both;
  padding-bottom:10px;
}

/* Effects */
#menu3{
  width:50px;
  position:absolute;
  top:85%;
  left:15%;
  transform: translate(-50%, -50%);
  z-index:1
  }
#effects{
  position:absolute; 
  bottom: 14.98%;
  left:14.98%;
  top:15%;
  right:15%;
  padding: 10px;
  overflow: auto;
}
.ar{
  height: 300px;;
  border:solid;
}
.ardiv{
  float: left;
  margin: 10px;
}
.arcap{
  color: #E82332;
  margin: 0;
}

/* Bulletin */
#menu4{
  width:50px;
  position:fixed;
  top:85%;
  left:85%;
  transform: translate(-50%, -50%);
  z-index:1;
}
.bulletin{
  max-width: 100%;
}
#Bulletin{
  position:absolute; 
  top:15%;
  left:15%;
  bottom:14.98%;
  right:14.98%;
  padding: 10px;
  overflow: scroll;
}

/* Contacts */
#menu0{
  width:50px;
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index:1;
}
#g{
  top:15%;
  left:15%;
}
#i{
  top:15%;
  left:85%;
}
#y{  
  top:85%;
  left:15%;
}
#m{ 
  top:85%;
  left:85%;
}
.sub{
  position:absolute;
  margin:0;
  color:#E82332;
  padding:10px;
  transform: translate(-50%, -50%)     
} 
/* Moblie */
@media all and (max-width:800px) 
{ body{
  overflow-x: hidden;
}  
  #desktop{
    left:10%
  } 
 .frame
    { 
     min-width:0;
     min-height: 0;
     width:100%;
     resize: none;
    }

  #imgbox{
    right:10%
  }
  #Bulletin{
    left:10%
  }
  .gallery{
    max-width: 90%;
  }
  #effects{
    right:10%
  }
}